/* eslint-disable @atlaskit/design-system/no-legacy-icons */
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Flex, Box, xcss } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { IconButton } from '@atlaskit/button/new';
import { JiraIcon } from '@atlaskit/logo';
import Lozenge from '@atlaskit/lozenge';

import { SidePanelHeader } from '@confluence/side-panel';
import { fg } from '@confluence/feature-gating';

import { messages } from './messages';
import type { IssueCreateSidePanelHeaderProps } from './__types__/IssueCreateSidePanelHeader';

const headerMessageStyle = xcss({
	paddingInline: 'space.100',
	font: 'font.heading.small',
	fontWeight: 'font.weight.medium',
	fontFamily: 'font.family.body',
	paddingBottom: 'space.025',
});

export const IssueCreateSidePanelHeader = ({ onClose }: IssueCreateSidePanelHeaderProps) => {
	return (
		<SidePanelHeader>
			<Flex direction="row" justifyContent="space-between" alignItems="center">
				<Heading size="medium" as="h2">
					<Flex direction="row" justifyContent="space-between" alignItems="center">
						<Box>
							<JiraIcon size="small" appearance="brand" />
						</Box>
						<Box xcss={headerMessageStyle}>
							<FormattedMessage {...messages.sidePanelHeader} />
						</Box>
						{fg('confluence_single_issue_create_ai_toggle') ? null : (
							<Lozenge
								style={{
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
									color: 'color.icon',
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
									backgroundColor: 'color.icon.disabled',
								}}
							>
								<FormattedMessage {...messages.sidePanelBetaText} />
							</Lozenge>
						)}
					</Flex>
				</Heading>
				<IconButton
					icon={() => <CrossIcon label="close-icon" size="small" />}
					appearance="subtle"
					label="close"
					onClick={() => onClose()}
					testId="close-issue-create-panel-button"
				/>
			</Flex>
		</SidePanelHeader>
	);
};
