import { AI_MATE_URL } from '../../common/constants';
import {
	AssistanceServiceError,
	AssistanceServiceMessageTemplate,
	type AssistanceServiceMessageTemplateValues,
	ConvoAiErrorPayload,
	FetchIssueCreateDataProps,
	SuggestedIssue,
} from '../../common/types';

const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export class ConvoAiError extends Error {
	constructor(
		readonly statusCode: number,
		readonly errorMessage: string,
		readonly message_template: AssistanceServiceMessageTemplateValues,
		readonly shouldSlo: boolean = true,
	) {
		super(errorMessage);
	}
}

export const fetchAiSuggestedFields = async ({
	cloudId,
	confluencePage,
	highlightedText,
	signal,
	product = 'confluence',
	experienceId = 'ai-issue-create-confluence-single',
}: FetchIssueCreateDataProps): Promise<SuggestedIssue | undefined> => {
	const response = await fetch(AI_MATE_URL, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Experience-Id': experienceId,
			'X-Product': product,
			'Atl-CloudId': cloudId,
		},
		body: JSON.stringify({
			recipient_agent_named_id: 'ai_issue_create_agent',
			agent_input_context: {
				application: capitalizeFirstLetter(product),
				context: {
					confluence_page_markdown_plus: confluencePage,
					highlighted_text: highlightedText,
				},
				suggested_issues_config: {
					max_issues: 1,
					suggested_issue_field_types: [
						{
							issue_type: 'Task',
							fields: [
								{
									field_name: 'Summary',
									field_type: 'Short-Text',
								},
								{
									field_name: 'Description',
									field_type: 'Paragraph',
								},
							],
						},
					],
				},
			},
		}),
		credentials: 'include',
		signal,
	});

	if (!response.ok) {
		const errorPayload = await response.json();
		const error = handleErrorPayload(response.status, errorPayload);
		throw error;
	}

	const data = await response.json();
	const suggestion = parseData(data);
	if (!suggestion) {
		throw new Error(`Parsing response from ${experienceId} failed`);
	}
	return suggestion;
};

const NON_SLO_ERRORS: AssistanceServiceMessageTemplateValues[] = [
	AssistanceServiceMessageTemplate.ACCEPTABLE_USE_VIOLATIONS,
	AssistanceServiceMessageTemplate.ACCEPTABLE_USE_EVALUATION_EXCEPTION,
	AssistanceServiceMessageTemplate.AI_DISABLED,
	AssistanceServiceMessageTemplate.CONVERSATION_CHANNEL_UNAUTHORIZED,
	AssistanceServiceMessageTemplate.EXCEEDING_CONTEXT_LENGTH_ERROR,
	AssistanceServiceMessageTemplate.EXPERIENCE_NOT_ENABLED_FOR_CUSTOMER_ACCOUNT,
	AssistanceServiceMessageTemplate.FEATURE_DISABLED,
	AssistanceServiceMessageTemplate.FEATURE_DISABLED_ON_SITE,
	AssistanceServiceMessageTemplate.HIPAA_CONTENT_DETECTED,
	AssistanceServiceMessageTemplate.OPENAI_RATE_LIMIT_USER_ABUSE,
	AssistanceServiceMessageTemplate.RATE_LIMIT,
];

export const isWhitelistedError = (messageTemplate: AssistanceServiceMessageTemplateValues) =>
	NON_SLO_ERRORS.includes(messageTemplate);

export const parseData = (data: AssistanceServiceError | any): SuggestedIssue | undefined => {
	if ('message_template' in data) {
		const shouldSlo = !isWhitelistedError(data.message_template);
		throw new ConvoAiError(data.status_code, data.content, data.message_template, shouldSlo);
	}

	try {
		const content = data?.message?.content;
		if (!content) {
			return undefined;
		}
		const contentJson = JSON.parse(content);

		if (contentJson.suggested_issues.length === 0) {
			throw new ConvoAiError(
				200,
				'No suggested issues are available for the context provided',
				AssistanceServiceMessageTemplate.NO_ANSWER_SUGGESTED_ISSUES,
			);
		}

		return {
			summary: contentJson.suggested_issues[0].field_values.Summary,
			description: contentJson.suggested_issues[0].field_values.Description,
		};
	} catch (error: Error | unknown) {
		if (error instanceof ConvoAiError) {
			throw error;
		}

		throw new ConvoAiError(
			200,
			`Failed to parse JSON: ${error}`,
			data.message_template ?? AssistanceServiceMessageTemplate.UNKNOWN_ERROR,
		);
	}
};

export const handleErrorPayload = (
	statusCode: number,
	errorPayload: ConvoAiErrorPayload,
): ConvoAiError => {
	const { content, errorMessage, message_template } = errorPayload;
	// `content` is available when error is from assistance-service
	// `errorMessage` is available when error is from convo-ai (usually only AI enabled errors)
	// `message_template` is available when error is from assistance-service in case content is empty
	const message = content ? content : errorMessage ?? message_template;
	return new ConvoAiError(
		statusCode,
		message ?? 'Unknown error',
		message_template ?? AssistanceServiceMessageTemplate.UNKNOWN_ERROR,
	);
};
