import { useCallback, useEffect, useRef, useState } from 'react';

import {
	AssistanceServiceMessageTemplate,
	type AssistanceServiceMessageTemplateValues,
	type SuggestedIssue,
} from '../../common/types';
import { useAiIssueCreateAnalytics } from '../ai-issue-create-analytics';

import { ConvoAiError, fetchAiSuggestedFields } from './utils';

type UseFetchAiSuggestedFields = {
	cloudId: string;
	/**
	 * Confluence page in markdown plus format
	 */
	confluencePage: string;
	/**
	 * The snippet of text that is highlighted in markdown plus format
	 */
	highlightedText: string;
	/**
	 * The X-Product to be used in the request to Assistance Service
	 */
	product: string;
	/**
	 * The X-Experience-Id to be used in the request to Assistance Service
	 */
	experienceId: string;
	/**
	 * Callback fired when state is changed
	 * @param newState - the new state
	 */
	onStateChange: (newState: FetchState) => void;
};

type AbortState = { state: 'abort'; fields: undefined };
type LoadingState = { state: 'loading'; fields: undefined };
type ErrorState = {
	state: 'error';
	error: Error | unknown;
	message_template: AssistanceServiceMessageTemplateValues;
	fields: undefined;
};
type SuccessState = { state: 'idle'; fields: SuggestedIssue };
type FetchState = AbortState | LoadingState | ErrorState | SuccessState;

export const useFetchAiSuggestedFields = ({
	cloudId,
	confluencePage,
	highlightedText,
	product,
	experienceId,
	onStateChange,
}: UseFetchAiSuggestedFields): FetchState & { trigger: () => Promise<void> } => {
	const { fireTrack } = useAiIssueCreateAnalytics();

	const [fetchState, setFetchState] = useState<FetchState>({ state: 'loading', fields: undefined });

	const abortControllerRef = useRef<AbortController | null>(null);

	const fetchAiFields = useCallback(async () => {
		if (!cloudId || !confluencePage) {
			return;
		}

		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		abortControllerRef.current = new AbortController();
		const currentAbortController = abortControllerRef.current;

		setFetchState({ state: 'loading', fields: undefined });

		try {
			fireTrack('aiIssueCreateFetchSuggestedFieldsRequest start');
			const suggestion = await fetchAiSuggestedFields({
				cloudId,
				confluencePage,
				experienceId,
				highlightedText,
				product,
				signal: currentAbortController.signal,
			});
			fireTrack('aiIssueCreateFetchSuggestedFieldsRequest complete', {
				haveResult: !!suggestion,
			});

			if (currentAbortController.signal.aborted) {
				return;
			}

			if (suggestion) {
				setFetchState({ state: 'idle', fields: suggestion });
			} else {
				setFetchState({
					state: 'error',
					error: new Error('Error on suggesting AI Issue Create from Assistance Service'),
					message_template: AssistanceServiceMessageTemplate.UNKNOWN_ERROR,
					fields: undefined,
				});
			}
		} catch (e: Error | ConvoAiError | unknown) {
			let statusCode: number | undefined;
			let errorMessage: string | undefined;
			let message_template: AssistanceServiceMessageTemplateValues =
				AssistanceServiceMessageTemplate.UNKNOWN_ERROR; // default to unknown
			let shouldSlo = true;

			if (e instanceof ConvoAiError) {
				statusCode = e.statusCode;
				errorMessage = e.errorMessage;
				message_template = e.message_template;
				shouldSlo = e.shouldSlo;
			} else if (e instanceof Error) {
				errorMessage = e.message;
			} else {
				errorMessage = `Error occurred when requesting to ${experienceId} agent`;
			}

			if (currentAbortController.signal.aborted) {
				setFetchState({ state: 'abort', fields: undefined });
				return;
			}

			fireTrack('aiIssueCreateFetchSuggestedFieldsRequest fail', {
				errorMessage,
				statusCode,
				message_template,
				shouldSlo,
			});

			setFetchState({ state: 'error', error: e, message_template, fields: undefined });
		}
	}, [cloudId, confluencePage, experienceId, fireTrack, highlightedText, product]);

	useEffect(() => {
		fetchAiFields();

		return () => {
			if (abortControllerRef.current) {
				abortControllerRef.current.abort();
			}
		};
	}, [fetchAiFields]);

	useEffect(() => {
		if (onStateChange) {
			onStateChange(fetchState);
		}
	}, [fetchState.state, fetchState.fields, onStateChange, fetchState]);

	const trigger = useCallback(() => {
		return fetchAiFields();
	}, [fetchAiFields]);

	return { ...fetchState, trigger };
};
